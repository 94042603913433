define("ember-composable-helpers/helpers/join", ["exports", "@ember/component/helper", "@ember/array", "ember-composable-helpers/utils/as-array"], function (_exports, _helper, _array, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.join = join;

  function join([separator, rawArray]) {
    let array = (0, _asArray.default)(rawArray);

    if ((0, _array.isArray)(separator)) {
      array = separator;
      separator = ',';
    }

    return array.join(separator);
  }

  var _default = (0, _helper.helper)(join);

  _exports.default = _default;
});