define("ember-json-viewer/components/simple-value", ["exports", "@ember/component", "@ember/object", "ember-json-viewer/templates/components/simple-value"], function (_exports, _component, _object, _simpleValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: "",
    layout: _simpleValue.default,
    // passed-in
    value: null,
    syntaxClass: (0, _object.computed)("type", function () {
      return `syntax-${this.get("type")}`;
    }),
    type: (0, _object.computed)("value", function () {
      let v = this.get("value");
      return [true, false].includes(v) ? "boolean" : Number.isFinite(v) ? "number" : typeof v === "string" ? "string" : v === null ? "null" : "unknown";
    }),
    formattedValue: (0, _object.computed)("value", "type", function () {
      let v = this.get("value");

      if (this.get("type") === "string") {
        return `"${v}"`;
      } else if (this.get("type") === "null") {
        return `null`;
      } else {
        return v;
      }
    })
  });

  _exports.default = _default;
});