define("ember-cli-chart/components/ember-chart", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global Chart */
  var _default = _component.default.extend({
    tagName: "canvas",
    attributeBindings: ["width", "height"],

    init() {
      this._super(...arguments);

      this.plugins = this.plugins || [];
    },

    didInsertElement() {
      this._super(...arguments);

      let context = this.element;
      let data = this.data;
      let type = this.type;
      let options = this.options;
      let plugins = this.plugins;
      let chart = new Chart(context, {
        type: type,
        data: data,
        options: options,
        plugins: plugins
      });
      this.set("chart", chart);
    },

    willDestroyElement() {
      this._super(...arguments);

      this.chart.destroy();
    },

    didUpdateAttrs() {
      this._super(...arguments);

      this.updateChart();
    },

    updateChart() {
      let chart = this.chart;
      let data = this.data;
      let options = this.options;
      let animate = this.animate;

      if (chart) {
        chart.data = data;
        chart.options = options;

        if (animate) {
          chart.update();
        } else {
          chart.update(0);
        }

        if (this.customLegendElement) {
          this.customLegendElement.innerHTML = chart.generateLegend();
        }
      }
    }

  });

  _exports.default = _default;
});