self.deprecationWorkflow = self.deprecationWorkflow || {}
self.deprecationWorkflow.config = {
  workflow: [
    { handler: "silence", matchId: "ember.built-in-components.import" },
    { handler: "silence", matchId: "ember.globals-resolver" },
    { handler: "silence", matchId: "deprecated-run-loop-and-computed-dot-access" },
    { handler: "silence", matchId: "array-observers" },
    { handler: "silence", matchId: "ember-source.deprecation-without-for" },
    { handler: "silence", matchId: "ember-source.deprecation-without-since" },
    { handler: "silence", matchId: "ember-simple-auth.mixins.data-adapter-mixin" },
    { handler: "silence", matchId: "ember-views.curly-components.jquery-element" },
    { handler: "silence", matchId: "this-property-fallback" },
    { handler: "silence", matchId: "ember-data:default-serializer" },
    { handler: 'silence', matchId: 'routing.transition-methods' },
    { handler: 'silence', matchId: 'manager-capabilities.modifiers-3-13' },
  ],
}
