define("ember-json-viewer/helpers/not", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function not([val]
  /*, hash*/
  ) {
    return !val;
  });

  _exports.default = _default;
});