define('convertCsvToArray', ['exports'], function (exports) { 'use strict';

	function unwrapExports (x) {
		return x && x.__esModule && Object.prototype.hasOwnProperty.call(x, 'default') ? x['default'] : x;
	}

	function createCommonjsModule(fn, module) {
		return module = { exports: {} }, fn(module, module.exports), module.exports;
	}

	var checkIfValid_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.checkIfValid = void 0;

	function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

	var checkIfValid = function checkIfValid(data, _ref) {
	  var separator = _ref.separator;

	  if (typeof data !== 'string') {
	    throw new Error("data has to be typeof: ".concat(_typeof(''), " but got typeof: ").concat(_typeof(data)));
	  } else if (!data.includes(separator)) {
	    throw new Error("data does not include separator: ".concat(separator));
	  }
	};

	exports.checkIfValid = checkIfValid;
	});

	unwrapExports(checkIfValid_1);
	var checkIfValid_2 = checkIfValid_1.checkIfValid;

	var checkIfValid_1$1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.checkIfValid = void 0;

	function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

	var checkIfValid = function checkIfValid(value) {
	  if (typeof value !== 'string') {
	    throw new Error("value has to be typeof: 'string' but got typeof: '".concat(_typeof(value), "'"));
	  }
	};

	exports.checkIfValid = checkIfValid;
	});

	unwrapExports(checkIfValid_1$1);
	var checkIfValid_2$1 = checkIfValid_1$1.checkIfValid;

	var lib = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.default = exports.convertStringToNumber = void 0;



	var convertStringToNumber = function convertStringToNumber(value) {
	  (0, checkIfValid_1$1.checkIfValid)(value);

	  if (Number.isNaN(Number(value))) {
	    // if not a number
	    return NaN;
	  }

	  var float = parseFloat(value); // check if integer

	  if (float % 1 === 0) {
	    var int = parseInt(value, 10);
	    return int;
	  }

	  return float;
	};

	exports.convertStringToNumber = convertStringToNumber;
	var _default = convertStringToNumber;
	exports.default = _default;
	});

	unwrapExports(lib);
	var lib_1 = lib.convertStringToNumber;

	var convertCsvToArrayOfArrays = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.convertCSVToArrayOfArrays = void 0;



	var convertCSVToArrayOfArrays = function convertCSVToArrayOfArrays(data, _ref) {
	  var header = _ref.header,
	      separator = _ref.separator;
	  var csv = data;
	  var array = [];
	  var rows = csv.split(/(?!\B"[^"]*)\n(?![^"]*"\B)/g);
	  rows.forEach(function (row, idx) {
	    var values = row.split(separator);
	    var checkedAndConvertedValues = [];

	    if (rows.length - 1 !== idx && (!header && idx !== 0 || header)) {
	      values.forEach(function (value) {
	        var convertedToNumber = (0, lib.convertStringToNumber)(value);
	        var thisValue = Number.isNaN(convertedToNumber) ? value : convertedToNumber;
	        checkedAndConvertedValues.push(thisValue);
	      });
	      array.push(checkedAndConvertedValues);
	    }
	  });
	  return array;
	};

	exports.convertCSVToArrayOfArrays = convertCSVToArrayOfArrays;
	});

	unwrapExports(convertCsvToArrayOfArrays);
	var convertCsvToArrayOfArrays_1 = convertCsvToArrayOfArrays.convertCSVToArrayOfArrays;

	var convertCsvToArrayOfObjects = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.convertCSVToArrayOfObjects = void 0;



	function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

	var convertCSVToArrayOfObjects = function convertCSVToArrayOfObjects(data, _ref) {
	  var header = _ref.header,
	      separator = _ref.separator,
	      unquote = _ref.unquote;
	  var csv = data;
	  var rows = csv.split(/(?!\B"[^"]*)\n(?![^"]*"\B)/g);
	  var array = [];
	  var separatorRegex = new RegExp("(?!\\B\"[^\"]*)".concat(separator, "(?![^\"]*\"").concat(separator, ")"), 'g');
	  var quotedRegex = new RegExp('^["\'](.*)["\']$', 'g');
	  var headerRow;
	  var headerObj;
	  var content = [];

	  var processCell = function processCell(cell) {
	    if (unquote && cell.match(quotedRegex)) {
	      return cell.replace(quotedRegex, '$1');
	    }

	    return cell;
	  };

	  rows.forEach(function (row, idx) {
	    if (idx === 0) {
	      headerRow = row.split(separatorRegex).map(function (cell) {
	        return processCell(cell);
	      });

	      if (header) {
	        array.push(headerRow);
	      }

	      headerRow.forEach(function (headerItem) {
	        headerObj = Object.assign({}, headerObj, _defineProperty({}, headerItem, undefined));
	      });
	    } else if (rows.length - 1 !== idx) {
	      var values = row.split(separatorRegex);
	      values.forEach(function (value, i) {
	        var processedValue = processCell(value);
	        var convertedToNumber = (0, lib.convertStringToNumber)(processedValue);
	        var thisValue = Number.isNaN(convertedToNumber) ? processedValue : convertedToNumber;
	        headerObj = Object.assign({}, headerObj, _defineProperty({}, headerRow[i], thisValue));
	      });
	      content.push(headerObj);
	    }
	  });
	  array.push.apply(array, content);
	  return array;
	};

	exports.convertCSVToArrayOfObjects = convertCSVToArrayOfObjects;
	});

	unwrapExports(convertCsvToArrayOfObjects);
	var convertCsvToArrayOfObjects_1 = convertCsvToArrayOfObjects.convertCSVToArrayOfObjects;

	var lib$1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.default = exports.convertCSVToArray = void 0;







	var convertCSVToArray = function convertCSVToArray(data) {
	  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
	      header = _ref.header,
	      type = _ref.type,
	      separator = _ref.separator,
	      unquote = _ref.unquote;

	  var thisOptions = {
	    header: header !== false,
	    type: type || 'object',
	    separator: separator || ',',
	    unquote: unquote !== false
	  };
	  (0, checkIfValid_1.checkIfValid)(data, thisOptions);

	  if (thisOptions.type === 'object') {
	    return (0, convertCsvToArrayOfObjects.convertCSVToArrayOfObjects)(data, thisOptions);
	  }

	  return (0, convertCsvToArrayOfArrays.convertCSVToArrayOfArrays)(data, thisOptions);
	};

	exports.convertCSVToArray = convertCSVToArray;
	var _default = convertCSVToArray;
	exports.default = _default;
	});

	var index = unwrapExports(lib$1);
	var lib_1$1 = lib$1.convertCSVToArray;

	exports.convertCSVToArray = lib_1$1;
	exports.default = index;

	Object.defineProperty(exports, '__esModule', { value: true });

});
