define("ember-json-viewer/helpers/is-last", ["exports", "@ember/component/helper", "@ember/debug", "ember-json-viewer/utils/value-types"], function (_exports, _helper, _debug, _valueTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Checks whether the given second argument (a key name or index)
   * is the last key/index in the given object-or-array
   *
   * @param {object|array} objOrArray
   * @param {string|number} keyOrIndex Must be a string if first arg is an object,
   * and number if first arg is an array
   * @returns {Boolean}
   */
  var _default = (0, _helper.helper)(function isLast([objOrArray, keyOrIndex]
  /*, hash*/
  ) {
    if ((0, _valueTypes.isObject)(objOrArray)) {
      let keys = Object.keys(objOrArray);
      return keys.indexOf(keyOrIndex) === keys.length - 1;
    } else if ((0, _valueTypes.isArray)(objOrArray)) {
      return keyOrIndex === objOrArray.length - 1;
    } else {
      (true && !(false) && (0, _debug.assert)(`Unexpected args to is-last ${objOrArray}, ${keyOrIndex}`, false));
    }
  });

  _exports.default = _default;
});