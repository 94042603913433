define("ember-keyboard/helpers/if-key", ["exports", "@ember/component/helper", "ember-keyboard/utils/is-key", "ember-keyboard/utils/listener-name", "@ember/debug"], function (_exports, _helper, _isKey, _listenerName, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function ifKey([keyCombo, callback]
  /*, hash*/
  ) {
    return function (event) {
      (true && !(typeof callback === 'function') && (0, _debug.assert)('ember-keyboard: You must pass a function as the second argument to the `if-key` helper', typeof callback === 'function'));
      (true && !(event instanceof KeyboardEvent) && (0, _debug.assert)('ember-keyboard: The `if-key` helper expects to be invoked with a KeyboardEvent', event instanceof KeyboardEvent));

      if ((0, _isKey.default)((0, _listenerName.default)(event.type, keyCombo), event)) {
        callback(event);
      }
    };
  });

  _exports.default = _default;
});