define("ember-json-viewer/templates/components/entry-viewer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "8swEZzMd",
    "block": "[[[10,\"li\"],[14,0,\"entry\"],[12],[1,\"\\n\"],[41,[30,0,[\"isToggleable\"]],[[[1,\"    \"],[11,1],[24,0,\"key is-toggleable\"],[16,\"data-test-toggle\",[30,0,[\"path\"]]],[4,[38,1],[[30,0],\"toggleExpanded\"],null],[12],[1,\"\\n      \"],[10,1],[14,0,\"key-expansion-state\"],[12],[1,\"\\n\"],[41,[30,0,[\"isExpanded\"]],[[[1,\"          \"],[2,[30,0,[\"expandedIcon\"]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[2,[30,0,[\"collapsedIcon\"]]],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n      \"],[10,1],[15,\"data-path\",[30,0,[\"path\"]]],[12],[1,\"\\n\"],[1,\"        \"],[1,[34,2]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,1],[14,0,\"key\"],[15,\"data-path\",[30,0,[\"path\"]]],[12],[1,\"\\n\"],[1,\"      \"],[1,[34,2]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[1,[28,[35,3],null,[[\"value\",\"showSummary\",\"depth\",\"displayOptions\",\"path\"],[[30,0,[\"value\"]],[28,[37,4],[[30,0,[\"isExpanded\"]]],null],[28,[37,5],[[30,0,[\"depth\"]]],null],[30,0,[\"displayOptions\"]],[30,0,[\"path\"]]]]]],[1,\"\\n\"],[41,[51,[30,0,[\"isLast\"]]],[[[1,\"    \"],[10,1],[14,0,\"entry-delimiter\"],[15,\"data-path\",[28,[37,7],[[30,0,[\"path\"]],\"@\",\",\"],null]],[12],[1,\",\"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[],false,[\"if\",\"action\",\"quotedKey\",\"value-viewer\",\"not\",\"inc\",\"unless\",\"concat\"]]",
    "moduleName": "ember-json-viewer/templates/components/entry-viewer.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});