define("ember-keyboard/modifiers/on-key", ["exports", "ember-modifier", "@ember/service", "@ember/object", "ember-keyboard/utils/listener-name", "ember-keyboard/utils/is-key"], function (_exports, _emberModifier, _service, _object, _listenerName, _isKey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const ONLY_WHEN_FOCUSED_TAG_NAMES = ['input', 'select', 'textarea'];
  let Klass;

  if (true) {
    var _class, _descriptor;

    /* This is an element modifier to trigger some behavior when
     * specified key combo is pressed. When used with a form element
     * (input, textarea, or select), the action fires only when element
     * has focus. When used with another element type, it will trigger the
     * passed action, OR if no action is passed, it will trigger a `click`
     * on the element. This allows for easy declaration of keyboard shortcuts
     * for anything clickable: In the following example, we trigger a
     * click on the button when the B key is pressed:
     *
     * <button
     *    type="button"
     *    {{on-key 'b'}}>
     *   Click me, or press "B"
     * </button>
     */
    Klass = (_class = class OnKeyModifier extends _emberModifier.default {
      constructor(...args) {
        super(...args);

        _initializerDefineProperty(this, "keyboard", _descriptor, this);

        _defineProperty(this, "keyboardPriority", 0);

        _defineProperty(this, "activatedParamValue", true);

        _defineProperty(this, "eventName", 'keydown');

        _defineProperty(this, "onlyWhenFocused", true);

        _defineProperty(this, "listenerName", void 0);
      }

      didReceiveArguments() {
        let [keyCombo, callback] = this.args.positional;
        let {
          activated,
          event,
          priority
        } = this.args.named;
        this.keyCombo = keyCombo;
        this.callback = callback;
        this.eventName = event || 'keydown';
        this.activatedParamValue = Object.keys(this.args.named).includes('activated') ? !!activated : undefined;
        this.keyboardPriority = priority ? parseInt(priority, 10) : 0;
        this.listenerName = (0, _listenerName.default)(this.eventName, this.keyCombo);

        if (this.args.named.onlyWhenFocused !== undefined) {
          this.onlyWhenFocused = this.args.named.onlyWhenFocused;
        } else {
          this.onlyWhenFocused = ONLY_WHEN_FOCUSED_TAG_NAMES.includes(this.element.tagName.toLowerCase());
        }
      }

      didInstall() {
        this.keyboard.register(this);

        if (this.onlyWhenFocused) {
          this.element.addEventListener('click', this.onFocus, true);
          this.element.addEventListener('focus', this.onFocus, true);
          this.element.addEventListener('focusout', this.onFocusOut, true);
        }
      }

      willRemove() {
        if (this.onlyWhenFocused) {
          this.element.removeEventListener('click', this.onFocus, true);
          this.element.removeEventListener('focus', this.onFocus, true);
          this.element.removeEventListener('focusout', this.onFocusOut, true);
        }

        this.keyboard.unregister(this);
      }

      onFocus() {
        this.isFocused = true;
      }

      onFocusOut() {
        this.isFocused = false;
      }

      get keyboardActivated() {
        if (this.activatedParamValue === false) {
          return false;
        }

        if (this.onlyWhenFocused) {
          return this.isFocused;
        }

        return true;
      }

      get keyboardFirstResponder() {
        if (this.onlyWhenFocused) {
          return this.isFocused;
        }

        return false;
      }

      canHandleKeyboardEvent(event) {
        return (0, _isKey.default)(this.listenerName, event);
      }

      handleKeyboardEvent(event, ekEvent) {
        if ((0, _isKey.default)(this.listenerName, event)) {
          if (this.callback) {
            this.callback(event, ekEvent);
          } else {
            this.element.click();
          }
        }
      }

    }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "keyboard", [_service.inject], {
      configurable: true,
      enumerable: true,
      writable: true,
      initializer: null
    }), _applyDecoratedDescriptor(_class.prototype, "onFocus", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onFocus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFocusOut", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onFocusOut"), _class.prototype)), _class);
  } else {
    Klass = class OnKeyModifier extends _emberModifier.default {
      didInstall() {
        throw new Error('ember-keyboard only supports the on-key element modifier in Ember 3.8 and higher.');
      }

    };
  }

  var _default = Klass;
  _exports.default = _default;
});