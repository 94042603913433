define("ember-json-viewer/helpers/inc", ["exports", "@ember/component/helper", "@ember/debug"], function (_exports, _helper, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function inc([num]
  /*, hash*/
  ) {
    (true && !(Number.isFinite(num)) && (0, _debug.assert)(`Expected Number.isFinite to be true for ${num}`, Number.isFinite(num)));
    return num + 1;
  });

  _exports.default = _default;
});