define("ember-power-select/helpers/ember-power-select-is-selected", ["exports", "@ember/component/helper", "@ember/array", "@ember/utils"], function (_exports, _helper, _array, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.emberPowerSelectIsSelected = emberPowerSelectIsSelected;

  // TODO: Make it private or scoped to the component
  function emberPowerSelectIsSelected([option, selected]) {
    if (selected === undefined || selected === null) {
      return false;
    }

    if ((0, _array.isArray)(selected)) {
      for (let i = 0; i < selected.length; i++) {
        if ((0, _utils.isEqual)(selected[i], option)) {
          return true;
        }
      }

      return false;
    } else {
      return (0, _utils.isEqual)(option, selected);
    }
  }

  var _default = (0, _helper.helper)(emberPowerSelectIsSelected);

  _exports.default = _default;
});