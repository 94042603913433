define("ember-json-viewer/components/json-viewer", ["exports", "@ember/component", "ember-json-viewer/templates/components/json-viewer", "@ember/debug", "ember-json-viewer/utils/json-stringify", "@ember/object"], function (_exports, _component, _jsonViewer, _debug, _jsonStringify, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ALLOWED_OPTIONS = ["expandedIcon", "collapsedIcon", "collapseDepth"]; // Returns [startNode, startOffset, endNode, endOffset]
  // Reverses the window selection if the anchor node is after the
  // focus node

  function getOrderedSelection() {
    let selection = window.getSelection();

    if (!selection) {
      return null;
    }

    let {
      anchorNode,
      anchorOffset,
      focusNode,
      focusOffset
    } = selection;

    if (focusNode.compareDocumentPosition(anchorNode) & Node.DOCUMENT_POSITION_FOLLOWING) {
      // Range is reversed
      return [focusNode, focusOffset, anchorNode, anchorOffset];
    } else {
      return [anchorNode, anchorOffset, focusNode, focusOffset];
    }
  }

  function getPath(node) {
    let path = null;

    while (!path && node) {
      if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.hasAttribute("data-path")) {
          return node.getAttribute("data-path");
        }
      }

      node = node.parentNode;
    }
  }

  var _default = _component.default.extend({
    classNames: ["json-viewer"],
    layout: _jsonViewer.default,
    // passed-in
    json: null,
    displayOptions: (0, _object.computed)("options", function () {
      let options = this.get("options") || {};
      (true && !(Object.keys(options).every(key => ALLOWED_OPTIONS.includes(key))) && (0, _debug.assert)(`Only allowed options are: ${ALLOWED_OPTIONS}`, Object.keys(options).every(key => ALLOWED_OPTIONS.includes(key))));
      return options;
    }),

    didInsertElement() {
      this._super(...arguments);

      this._copyHandler = evt => {
        let [startNode, startOffset, endNode, endOffset] = getOrderedSelection();
        let startPath = getPath(startNode);
        let endPath = getPath(endNode);
        let range = {
          start: {
            path: startPath,
            index: startOffset
          },
          end: {
            path: endPath,
            index: endOffset
          }
        };
        let str = (0, _jsonStringify.default)(this.get("json"), range);
        evt.clipboardData.setData("text/plain", str);
        evt.preventDefault();
      };

      this.element.addEventListener("copy", this._copyHandler);
    },

    willDestroyElement() {
      this._super(...arguments);

      this.element.removeEventListener("copy", this._copyHandler);
      this._copyHandler = null;
    }

  });

  _exports.default = _default;
});