define("ember-json-viewer/templates/components/json-viewer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Zrgy5WxE",
    "block": "[[[1,[28,[35,0],null,[[\"value\",\"depth\",\"displayOptions\",\"path\"],[[30,0,[\"json\"]],0,[30,0,[\"displayOptions\"]],\"$\"]]]],[1,\"\\n\"]],[],false,[\"value-viewer\"]]",
    "moduleName": "ember-json-viewer/templates/components/json-viewer.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});