define("ember-json-viewer/components/entry-viewer", ["exports", "@ember/component", "@ember/object", "ember-json-viewer/templates/components/entry-viewer", "@ember/object/computed", "ember-json-viewer/utils/value-types"], function (_exports, _component, _object, _entryViewer, _computed, _valueTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: "",
    layout: _entryViewer.default,
    _isExpanded: null,
    isExpanded: (0, _object.computed)("depth", "collapseDepth", "_isExpanded", function () {
      if (this.get("_isExpanded") !== null) {
        return this.get("_isExpanded");
      }

      let depth = this.get("depth");
      let collapseDepth = this.get("collapseDepth");

      if (Number.isInteger(collapseDepth)) {
        return depth < collapseDepth;
      } else {
        return true;
      }
    }),
    // passed-in
    value: null,
    depth: 0,
    collapseDepth: (0, _computed.readOnly)("displayOptions.collapseDepth"),
    expandedIcon: (0, _computed.readOnly)("displayOptions.expandedIcon"),
    collapsedIcon: (0, _computed.readOnly)("displayOptions.collapsedIcon"),
    // It is important that this be a single text node so that the
    // selection offset is correct for copy/paste
    quotedKey: (0, _object.computed)("key", function () {
      return `"${this.get("key")}": `;
    }),
    isToggleable: (0, _object.computed)("value", function () {
      return !(0, _valueTypes.isPrimitive)(this.get("value"));
    }),
    actions: {
      toggleExpanded() {
        if (!this.get("isToggleable")) {
          return;
        }

        this.set("_isExpanded", !this.get("isExpanded"));
      }

    }
  });

  _exports.default = _default;
});