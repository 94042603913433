define("ember-json-viewer/templates/components/simple-value", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "i3Nol+If",
    "block": "[[[10,1],[15,0,[29,[\"value primitive \",[30,0,[\"syntaxClass\"]]]]],[15,\"data-path\",[30,0,[\"path\"]]],[12],[1,\"\\n  \"],[1,[30,0,[\"formattedValue\"]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "ember-json-viewer/templates/components/simple-value.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});