define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-us", {
    "header": {
      "labels": {
        "conversions": "Conversions",
        "features": "Features",
        "newPractice": "New Practice",
        "recent": "Recent",
        "reports": "Reports",
        "searchPlaceholder": {
          "practices": "Search Practices...",
          "users": "Search Users..."
        },
        "searchType": {
          "practices": "Practices",
          "users": "Patients"
        }
      }
    },
    "sidebar": {
      "headings": {
        "fluxMigrations": "Flux migrations",
        "onboarding": "Onboarding",
        "practice": "Practice IN SIDEBAR",
        "subscription": "Subscription",
        "tools": "Tools"
      },
      "labels": {
        "fluxMigrations": {
          "copyPractitioners": "Copy Practitioners",
          "copySetupInformation": "Copy Setup Information",
          "ingestions": "Ingestions",
          "postIngestionTasks": "Post Ingestion Tasks"
        },
        "onboarding": {
          "integrationTasks": "Integration Tasks",
          "migrations": "Migrations",
          "taskRuns": "Task runs",
          "tasks": "Tasks"
        },
        "practice": {
          "addressAndOptions": "Address & Options",
          "features": "Features",
          "settings": "Settings",
          "sites": "Sites",
          "users": "Users"
        },
        "subscription": {
          "details": "Details",
          "invoices": "Invoice",
          "usage": "Usage"
        },
        "tools": {
          "deactiveTreatments": "Deactivate treatments tool",
          "nhsLocale": "NHS Locale Tool",
          "phoneAndChat": "Phone & Chat",
          "toothStatusBackfill": "Tooth Status backfill tool"
        }
      },
      "pages": {
        "fluxMigrations": {
          "copyPractitioners": {
            "breadcrumbs": "Copy practitioners from {sourceSiteName} to {targetSiteName}",
            "table": {
              "active": "Active",
              "calendarPosition": "Calendar position",
              "colour": "Colour",
              "importId": "Import ID",
              "name": "Name",
              "providerNumber": "Provider Number",
              "status": "Status"
            }
          }
        },
        "onboarding": {},
        "practice": {
          "addressAndOptions": {
            "betaOptions": {
              "heading": "Beta Options",
              "labels": {
                "enableBeta": "Enable beta features"
              }
            },
            "otherOptions": {
              "heading": "Other Options",
              "labels": {
                "apiAccess": "API Access",
                "billingWarning": "Billing Warning",
                "dataExport": "Data export",
                "multiSite": "Multisite",
                "vip": "VIP"
              },
              "tooltips": {
                "billingWarning": "Shows an orange warning banner for level 4 users if the payment info hasn't been setup",
                "dataExport": "Allows the practice to export their data from Dentally as CSVs",
                "vip": "VIP Dentally customer"
              }
            },
            "practiceInfo": {
              "heading": "Practice information",
              "labels": {
                "name": "Name",
                "owner": "Owner",
                "practiceAddress": "Practice address",
                "slug": "Slug",
                "timezone": "Timezone",
                "website": "Website"
              }
            }
          },
          "sites": {},
          "users": {}
        },
        "subscription": {},
        "tools": {}
      }
    }
  }]];
  _exports.default = _default;
});