define("ember-json-viewer/components/value-viewer", ["exports", "@ember/component", "@ember/object", "ember-json-viewer/templates/components/value-viewer", "ember-json-viewer/utils/value-types", "@ember/debug"], function (_exports, _component, _object, _valueViewer, _valueTypes, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: "",
    layout: _valueViewer.default,
    // passed-in
    value: null,
    showSummary: false,
    isPrimitive: (0, _object.computed)("value", function () {
      return (0, _valueTypes.isPrimitive)(this.get("value"));
    }),
    prefix: (0, _object.computed)("value", function () {
      return (0, _valueTypes.isArray)(this.get("value")) ? "[" : "{";
    }),
    suffix: (0, _object.computed)("value", function () {
      return (0, _valueTypes.isArray)(this.get("value")) ? "]" : "}";
    }),
    isObj: (0, _object.computed)("value", function () {
      return (0, _valueTypes.isObject)(this.get("value"));
    }),
    valueSummary: (0, _object.computed)("value", function () {
      let v = this.get("value");
      (true && !(!(0, _valueTypes.isPrimitive)(v)) && (0, _debug.assert)(`valueSummary only possible for non-primitive, got ${v}`, !(0, _valueTypes.isPrimitive)(v)));
      return (0, _valueTypes.isArray)(v) ? v.length : Object.keys(v).length;
    })
  });

  _exports.default = _default;
});