define("ember-json-viewer/utils/value-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isArray = isArray;
  _exports.isObject = isObject;
  _exports.isPrimitive = isPrimitive;

  function isArray(v) {
    return Array.isArray(v);
  }

  function isPrimitive(v) {
    return v === null || ["number", "boolean", "string"].includes(typeof v);
  }

  function isObject(v) {
    return !isArray(v) && typeof v === "object";
  }
});