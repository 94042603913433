define("ember-composable-helpers/helpers/intersect", ["exports", "@ember/component/helper", "@ember/array", "ember-composable-helpers/utils/as-array"], function (_exports, _helper, _array, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.intersect = intersect;

  function intersect([...arrays]) {
    let confirmedArrays = (0, _asArray.default)(arrays).map(array => {
      return (0, _array.isArray)(array) ? array : [];
    }); // copied from https://github.com/emberjs/ember.js/blob/315ec6472ff542ac714432036cc96fe4bd62bd1f/packages/%40ember/object/lib/computed/reduce_computed_macros.js#L1063-L1100

    let results = confirmedArrays.pop().filter(candidate => {
      for (let i = 0; i < confirmedArrays.length; i++) {
        let found = false;
        let array = confirmedArrays[i];

        for (let j = 0; j < array.length; j++) {
          if (array[j] === candidate) {
            found = true;
            break;
          }
        }

        if (found === false) {
          return false;
        }
      }

      return true;
    });
    return results;
  }

  var _default = (0, _helper.helper)(intersect);

  _exports.default = _default;
});