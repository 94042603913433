define("ember-composable-helpers/helpers/flatten", ["exports", "@ember/component/helper", "@ember/array", "ember-composable-helpers/utils/as-array"], function (_exports, _helper, _array, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.flatten = flatten;

  function flatten(array) {
    if (!(0, _array.isArray)(array)) {
      return array;
    }

    return (0, _asArray.default)(array).reduce((flattened, el) => {
      return flattened.concat(flatten(el));
    }, []);
  }

  var _default = (0, _helper.helper)(function ([array]) {
    return flatten(array);
  });

  _exports.default = _default;
});