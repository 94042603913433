define("ember-composable-helpers/helpers/pipe", ["exports", "@ember/component/helper", "ember-composable-helpers/utils/is-promise"], function (_exports, _helper, _isPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.invokeFunction = invokeFunction;
  _exports.pipe = pipe;

  function invokeFunction(acc, curr) {
    if ((0, _isPromise.default)(acc)) {
      return acc.then(curr);
    }

    return curr(acc);
  }

  function pipe(actions = []) {
    return function (...args) {
      return actions.reduce((acc, curr, idx) => {
        if (idx === 0) {
          return curr(...args);
        }

        return invokeFunction(acc, curr);
      }, undefined);
    };
  }

  var _default = (0, _helper.helper)(pipe);

  _exports.default = _default;
});