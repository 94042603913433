define("ember-notify/initializer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize() {
    let application = arguments[1] || arguments[0];
    application.inject('route', 'notify', 'service:notify');
    application.inject('controller', 'notify', 'service:notify');
  }

  var _default = {
    name: 'inject-notify-service',
    initialize: initialize
  };
  _exports.default = _default;
});